import { Component } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Phone from '../components/Phone';
import Offer from './newOfferModal';
const Item = props => (
  <Link href={props.href} rel="noreferrer">
    <a
      onClick={props.onClick}
      rel="noreferrer"
      className={st['nav-item'] + ' relative block font-normal md:font-bold md:text-14 md:text-navbar py-10 pl-13 md:pl-23'}
      area-label={'links'}
    >
      {props.children}
      <style jsx>{`
        .nav-item {
          position: relative;
          z-index: 1500;
          text-transform: ${props.level === 1 ? 'capitalize' : 'capitalize'};
          font-weight: ${props.level === 1 ? '500' : 'normal'};
          font-size: ${props.level === 1 ? '16px' : '16px'};
          ${!props.level && 'color:#fff'};
        }
        @media screen and (min-width: 768px) {
          .nav-item {
            font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
            text-transform: ${props.level === 1 ? 'uppercase' : 'capitalize'};
            font-weight: ${props.level === 1 ? 'bold' : 'normal'};
            z-index: 1500;
            position: relative;
          }
        }
        @media screen and (max-width: 768px) {
          .nav-item {
            color: #000;
            z-index: 1500;
          }
        }
      `}</style>
    </a>
  </Link>
);
import st from './style.module.css';
class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.click = this.click.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }
  click(e) {
    document.querySelectorAll('.dropdown-title').forEach(el => {
      if (el.innerHTML !== e.target.innerHTML) {
        el.parentNode.classList.remove('show');
        el.parentNode.classList.add('hide');
        el.parentNode.classList.remove('clicked');
      }
    });
    if (Array.from(e.target.parentNode.classList).indexOf('hide') > -1 && Array.from(e.target.parentNode.classList).indexOf('clicked') > -1) {
      e.target.parentNode.classList.add('hide');
      e.target.parentNode.classList.remove('show');
      e.target.parentNode.classList.remove('clicked');
      return;
    } else if (Array.from(e.target.parentNode.classList).indexOf('show') > -1 && Array.from(e.target.parentNode.classList).indexOf('clicked') > -1) {
      e.target.parentNode.classList.add('hide');
      e.target.parentNode.classList.remove('show');
      e.target.parentNode.classList.remove('clicked');
      return;
    } else if (Array.from(e.target.parentNode.classList).indexOf('hide') > -1) {
      e.target.parentNode.classList.remove('hide');
      e.target.parentNode.classList.add('show');
      e.target.parentNode.classList.add('clicked');
      return;
    } else if (Array.from(e.target.parentNode.classList).indexOf('show') > -1) {
      e.target.parentNode.classList.add('hide');
      e.target.parentNode.classList.remove('show');
      e.target.parentNode.classList.add('clicked');
      return;
    }
  }
  show() {
    this.setState({ show: true });
  }
  hide() {
    this.setState({ show: false });
  }
  render() {
    return (
      <div className={'relative element-for-check dropdown-container pl-13 md:pl-0 hide '}>
        {this.props.image && <img src={this.props.image} width="22" className={' absolute top-10 mr-10'} alt={this.props.title} />}
        <span
          onClick={this.click}
          className={
            'dropdown-title relative block text-16 md:text-14 md:text-navbar md:uppercase font-medium md:font-bold py-10 md:pl-13 md:pr-13 cursor-pointer ' +
            (this.props.image ? 'left-30' : '')
          }
        >
          {this.props.title}
          <style jsx>{`
            @media screen and (min-width: 768px) {
              .dropdown-title {
                ${this.props.level > 1 && 'color:white;'}
                ${this.props.level > 1 && 'text-transform:initial;'}
                ${this.props.level > 1 && 'font-weight:400;'}
              }
            }
          `}</style>
        </span>
        <div className={'dropdown md:mt-2 md:absolute left-0 md:bg-purple min-w-10 md:text-white md:top-85p '}>{this.props.children}</div>
        <span onClick={this.click} className="navbar-arrow md:hidden " />
      </div>
    );
  }
}
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showOfferModal: false,
      offerPrice: 0,
      offerModalTitle: '',
      offerDuration: 0,
    };
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    const that = this;
    document.querySelector('#maincontent').addEventListener('click', function () {
      that.setState({ show: false });
      document.querySelectorAll('.dropdown-container').forEach(el => {
        el.classList.remove('show');
        el.classList.remove('clicked');
        el.classList.add('hide');
      });
    });
  }
  toggle() {
    this.setState({ show: !this.state.show });
    if (!this.state.show) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.overflowX = 'hidden';
      document.documentElement.style.overflow = 'auto';
    }
  }
  hideOfferModal = () => {
    this.setState({ showOfferModal: false });
    document.documentElement.style.overflow = 'auto';
  };
  closeAll = () => {
    alert();
  };
  render() {
    // const phone_number = <Phone country={this.props.country} continent={this.props.continent}/>;
    return (
      <div className="container z-1000  h-80 py-10 md:py-0" id="header_1">
        <nav className="relative h-full  h-80 flex justify-between items-center w-full ">
          <Link href="/">
            <a className="ml-auto mr-0 inline-block" rel="noreferrer" area-label={'links'}>
              <div className="hidden lg:block md:py-3 md:leading-none">
                <Image src="/images/logovertical.svg" width={64} height={44} layout="fixed" alt="logo" />
              </div>
              <div className="lg:hidden">
                <Image src="/images/logo-mobile.svg" width={55} height={38} layout="fixed" alt="logo mobile" />
              </div>
            </a>
          </Link>
          <button className="absolute left-0 top-auto md:hidden" type="button" aria-label="button">
            <span className="flex flex-col justify-between w-30 h-20" onClick={this.toggle}>
              <span className="w-full h-4 bg-black" />
              <span className="w-10/12 h-4 bg-black" />
              <span className="w-8/12 h-4 bg-black" />
            </span>
          </button>
          <div className="hidden md:block h-80 relative left-0 w-screen z-200">
            <div className="absolute md:relative  bg-white md:bg-transparent h-full w-10/12 md:w-auto  pb-100 md:pb-0 table md:flex md:justify-end">
              <ul className="flex flex-col md:flex-row md:justify-center leading-none">
                <li className="bg-gray-light h-full mb-2">
                  <div className="relative">
                    <div
                      className={
                        st['header-nav-item'] +
                        ' h-80 flex items-center cursor-pointer  hover:bg-red-header px-20 font-bolder text-16 text-gray-headerIcon hover:text-purple uppercase'
                      }
                    >
                      <span className={'text-black mr-10'}>Services</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width={15} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                      <div className={'absolute top-80 -left-10 bg-red-header w-auto min-w-520 p-24 hidden ' + st['expended-nav-item']}>
                        <div className={'flex'}>
                          <div className="bg-red-headerInner p-24 w-260 h-300">
                            <div className={'flex items-center cursor-default'}>
                              {/*<Lottie config={{animationData: lamp,loop:true}} width={40}  playingState={'playing'}  />*/}
                              <img src="https://weprodigi.com/uploads/images/header/servises.svg" width={28} alt="servises" />
                              <span className={'ml-10 text-black'}>Production</span>
                            </div>
                            <div className="items mt-10">
                              <a href="/video-production" className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}>
                                <span className={'mr-10'}>Video Production</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                              <a href="/design" className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}>
                                <span className={'mr-10'}>Design Services</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                              <a
                                href="https://weprodigi.com/main/social-media-fun"
                                className={'py-10 text-16 item font-normal capitalize flex ' + st['link-item']}
                              >
                                <span className={'mr-10'}> Game Creation</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                              <a
                                href="https://weprodigi.com/main/digital-ad-banners"
                                className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}
                              >
                                <span className={'mr-10'}> Banner Production</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div className="bg-red-headerInner p-24 w-296 h-300 ml-24">
                            <a href={'/social-media-marketing'} className={'flex items-center cursor-default'}>
                              {/*thumb*/}
                              {/*<Lottie config={{animationData: thumb,loop:true}} width={40}  playingState={'playing'}  />*/}
                              <img src="https://weprodigi.com/uploads/images/header/digital.svg" width={28} alt="digital" />
                              <span className={'ml-10 text-black'}>Social</span>
                            </a>
                            <div className="items mt-10">
                              <a href="/social-media-marketing" className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}>
                                <span className={'mr-10'}>Social Media Management</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                              <a
                                href="https://weprodigi.com/facebook-marketing-services"
                                className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}
                              >
                                <span className={'mr-10'}>Facebook Marketing</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                              <a
                                href="https://weprodigi.com/instagram-marketing-services"
                                className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}
                              >
                                <span className={'mr-10'}>Instagram Marketing</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                              <a
                                href="https://weprodigi.com/linkedin-marketing-services"
                                className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}
                              >
                                <span className={'mr-10'}>Linkedin Marketing</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                              <a
                                href="https://weprodigi.com/twitter-marketing-services"
                                className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}
                              >
                                <span className={'mr-10'}>Twitter Marketing</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                              <a
                                href="https://weprodigi.com/snapchat-marketing-services"
                                className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}
                              >
                                <span className={'mr-10'}>Snapchat Marketing</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div className="bg-red-headerInner p-24 w-296 h-300 ml-24">
                            <div className={'flex items-center cursor-default'}>
                              {/*thumb*/}
                              {/*<Lottie config={{animationData: thumb,loop:true}} width={40}  playingState={'playing'}  />*/}
                              <img src="https://weprodigi.com/uploads/images/header/digital-marketing.svg" width={28} alt="digital-marketing" />
                              <span className={'ml-10 text-black'}>Digital Marketing</span>
                            </div>
                            <div className="items mt-10">
                              <a
                                href="https://weprodigi.com/seo/professional-seo-services"
                                className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}
                              >
                                <span className={'mr-10'}>SEO Services</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                              <a
                                href="https://weprodigi.com/paid-advertising-services"
                                className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}
                              >
                                <span className={'mr-10'}>Paid Advertising Services</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                              <a
                                href="https://weprodigi.com/main/messenger-chatbots-and-stickers"
                                className={'py-10 text-16 font-normal capitalize flex ' + st['link-item']}
                              >
                                <span className={'mr-10'}>Messenger Chatbots </span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={14}
                                  className={st['link-item-svg']}
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="bg-gray-light h-full mb-2">
                  <div className="relative">
                    <div
                      className={
                        st['header-nav-item'] +
                        ' h-80 flex items-center cursor-pointer  hover:bg-red-header px-20 font-bolder text-16 text-gray-headerIcon hover:text-purple uppercase'
                      }
                    >
                      <span className={'text-black mr-10'}>Clients</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width={15} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                      <div className={'absolute top-80 left-0 bg-red-header w-230 p-24 hidden ' + st['expended-nav-item']}>
                        <a href="https://weprodigi.com/main/cases" className={'pb-10 text-16  font-normal capitalize flex ' + st['link-item']}>
                          <span className={'mr-10'}>Cases</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width={14} className={st['link-item-svg']} viewBox="0 0 20 20" fill="currentColor">
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                        <a href="https://weprodigi.com/testimonals" className={'py-10 text-16   font-normal capitalize flex ' + st['link-item']}>
                          <span className={'mr-10'}>Testimonials</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width={14} className={st['link-item-svg']} viewBox="0 0 20 20" fill="currentColor">
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                        <a href="/industries" className={'pt-10 text-16 font-normal capitalize flex ' + st['link-item']}>
                          <span className={'mr-10'}>Industries</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width={14} className={st['link-item-svg']} viewBox="0 0 20 20" fill="currentColor">
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="bg-gray-light h-full mb-2">
                  <div className="relative">
                    <div
                      className={
                        st['header-nav-item'] +
                        ' h-80 flex items-center cursor-pointer  hover:bg-red-header px-20 font-bolder text-16 text-gray-headerIcon hover:text-purple uppercase'
                      }
                    >
                      <span className={'text-black mr-10'}>About Us</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width={15} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                      <div className={'absolute top-80 left-0 bg-red-header w-230 p-24 hidden ' + st['expended-nav-item']}>
                        <a href="/contact-us" className={'pb-10 text-16 font-normal capitalize flex ' + st['link-item']}>
                          <span className={'mr-10'}>Contact us</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width={14} className={st['link-item-svg']} viewBox="0 0 20 20" fill="currentColor">
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                        <a href="/about-us" className={'pb-10 pt-10 text-16 font-normal capitalize flex ' + st['link-item']}>
                          <span className={'mr-10'}>About Company</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width={14} className={st['link-item-svg']} viewBox="0 0 20 20" fill="currentColor">
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                        <a href="https://weprodigi.com/team" className={'pt-10 text-16 font-normal capitalize flex ' + st['link-item']}>
                          <span className={'mr-10'}>Team</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width={14} className={st['link-item-svg']} viewBox="0 0 20 20" fill="currentColor">
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="bg-gray-light h-full mb-2">
                  <div className="relative">
                    <a
                      href={'/blog'}
                      className={
                        st['header-nav-item'] +
                        ' h-80 flex items-center cursor-pointer  hover:bg-red-header px-20 font-bolder text-16 text-gray-headerIcon hover:text-purple uppercase'
                      }
                    >
                      <span className={'text-black mr-10'}>Blog</span>
                    </a>
                  </div>
                </li>
                <li className="bg-gray-light h-full mb-2">
                  <div className="relative">
                    <div
                      className={
                        st['header-nav-item'] +
                        ' h-80 flex items-center cursor-pointer  hover:bg-red-header px-20 font-bolder text-16 text-gray-headerIcon hover:text-purple uppercase'
                      }
                    >
                      <span className={'text-black mr-10'}>Resources</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width={15} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                      <div className={'absolute top-80 -right-100 bg-red-header w-auto  p-24 hidden ' + st['expended-nav-item']}>
                        <div className={'flex'}>
                          <div className="bg-red-headerInner p-24 w-296 h-full ">
                            <div className={'flex items-center cursor-default'}>
                              <img src="https://weprodigi.com/uploads/images/header/video.svg" width={28} alt="video" />
                              <span className={'ml-10 text-black'}>Video Production</span>
                            </div>
                            <div className="items mt-10">
                              <div className={'relative ' + st['nav-item']}>
                                <div className={' py-10 text-16 font-medium capitalize flex ' + st['link-item']}>
                                  <span className={'mr-10'}>Industries</span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={14}
                                    className={st['link-item-svg']}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                  </svg>
                                </div>
                                <div className={st['nav-item-hidden'] + ' h-0 pl-10 font-normal text-14 overflow-hidden'}>
                                  <a href="/healthcare" className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}>
                                    <span className={'mr-10'}>Healthcare Video</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                  <a href="/financial" className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}>
                                    <span className={'mr-10'}>Financial Video</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                  <a
                                    href="https://weprodigi.com/main/startup"
                                    className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}
                                  >
                                    <span className={'mr-10'}>Startup Video</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>{' '}
                                  <a
                                    href="https://weprodigi.com/SAAS-videos"
                                    className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}
                                  >
                                    <span className={'mr-10'}>SAAS Video</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                </div>
                              </div>
                              <div className={'relative ' + st['nav-item']}>
                                <div className={' py-10 text-16 font-medium capitalize flex ' + st['link-item']}>
                                  <span className={'mr-10'}>Video Styles</span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={14}
                                    className={st['link-item-svg']}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                  </svg>
                                </div>
                                <div className={st['nav-item-hidden'] + ' h-0 pl-10 font-normal text-14 overflow-hidden'}>
                                  <a
                                    href="https://weprodigi.com/main/2d-animation"
                                    className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}
                                  >
                                    <span className={'mr-10'}>2D Animation</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                  <a
                                    href="https://weprodigi.com/main/3d-animation"
                                    className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}
                                  >
                                    <span className={'mr-10'}>3D Animation</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                  <a
                                    href="https://weprodigi.com/main/whiteboard-animation"
                                    className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}
                                  >
                                    <span className={'mr-10'}>Whiteboard Animation</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                </div>
                              </div>
                              <div className={'relative ' + st['nav-item']}>
                                <div className={' py-10 text-16 font-medium capitalize flex ' + st['link-item']}>
                                  <span className={'mr-10'}>Video Types</span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={14}
                                    className={st['link-item-svg']}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                  >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                  </svg>
                                </div>
                                <div className={st['nav-item-hidden'] + ' h-0 pl-10 font-normal text-14 overflow-hidden'}>
                                  <a
                                    href="https://weprodigi.com/video-production/explainer-videos"
                                    className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}
                                  >
                                    <span className={'mr-10'}>Explainer Video</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                  <a
                                    href="https://weprodigi.com/main/social-media-videos"
                                    className={'py-10 text-14  font-normal capitalize flex ' + st['link-item']}
                                  >
                                    <span className={'mr-10'}>Social Media Videos</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                  <a
                                    href="https://weprodigi.com/main/commercial-videos"
                                    className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}
                                  >
                                    <span className={'mr-10'}>Commercial Video</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                  <a
                                    href="https://weprodigi.com/main/product-videos"
                                    className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}
                                  >
                                    <span className={'mr-10'}>Product Video</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                  <a
                                    href="https://weprodigi.com/main/software-feauture-videos"
                                    className={'py-10 text-14 font-normal capitalize flex ' + st['link-item']}
                                  >
                                    <span className={'mr-10'}>Software Video</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={14}
                                      className={st['link-item-svg']}
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="h-full flex items-center justify-end overflow-hidden">
                  <button
                    className={st['span-width-animation-parent'] + ' relative button  w-180 ' + st['type1']}
                    onClick={() => {
                      this.setState({ showOfferModal: true });
                      document.documentElement.style.overflow = 'hidden';
                    }}
                  >
                    Get a Proposal
                    <span
                      className={st['span-width-animation'] + ' absolute w-0 h-30'}
                      onClick={() => {
                        this.setState({ showOfferModal: true });
                        document.documentElement.style.overflow = 'hidden';
                      }}
                    >
                      {' '}
                    </span>
                    <span className={st.second + ' left-0 top-0 block  text-black absolute w-180 leading-30 h-30 opacity-0'}> Get a Proposal</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className={'absolute right-30 top-3px  h-6 w-6 inline-block ' + st['button-arrow']}
                      viewBox="0 0 20 20"
                      fill="#ffffff"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
            <div onClick={this.toggle} className="md:hidden w-2/12 h-full absolute right-0 top-0" />
          </div>
          <div
            className={
              'md:hidden flex flex-col fixed top-0 bottom-0 -left-full transform ' +
              (this.state.show ? 'translate-x-full' : 'translate-x-0') +
              ' transition-all duration-300 ease-linear w-screen z-500'
            }
          >
            <div className="w-2/12 h-full absolute top-0 right-0" onClick={this.toggle} />
            <div className="flex overflow-x-hidden flex-col bg-white w-10/12 h-full px-20 py-30 overflow-y-scroll">
              <div className="flex items-center justify-between">
                <Link href="/">
                  <a className="leading-none" rel="noreferrer" onClick={this.toggle} area-label={'links'}>
                    <div className="lg:hidden">
                      <img src="/images/logo-menu.svg" width={55} height={38} layout="fixed" alt="logo menu" />
                    </div>
                  </a>
                </Link>
                <span className="text-20 font-bold" onClick={this.toggle}>
                  X
                </span>
              </div>
              <div className="flex flex-grow flex-col mt-30">
                <div className="mb-30">
                  <p className="text-purple text-18 font-medium uppercase my-5">Services</p>
                  <Dropdown title="Production" image={'https://weprodigi.com/uploads/images/header/servises.svg'} level={2}>
                    <Item onClick={this.toggle} href="/video-production">
                      Video Production
                    </Item>
                    <Item onClick={this.toggle} href="/design" area-label={'links'}>
                      Design Services
                    </Item>
                    <Item href="https://weprodigi.com/main/social-media-fun">Game Creation</Item>
                    <Item href="https://weprodigi.com/main/digital-ad-banners">Banner Production</Item>
                  </Dropdown>
                  <Dropdown title="Social" image={'https://weprodigi.com/uploads/images/header/digital.svg'} level={2} onClick={this.closeAll}>
                    <Item onClick={this.toggle} href="/social-media-marketing">
                      Social Media Marketing
                    </Item>
                    <Item onClick={this.toggle} href="https://weprodigi.com/facebook-marketing-services">
                      Facebook Marketing
                    </Item>
                    <Item onClick={this.toggle} href="https://weprodigi.com/instagram-marketing-services">
                      Instagram Marketing
                    </Item>
                    <Item onClick={this.toggle} href="https://weprodigi.com/linkedin-marketing-services">
                      Linkedin Marketing
                    </Item>
                    <Item onClick={this.toggle} href="https://weprodigi.com/twitter-marketing-services">
                      Twitter Marketing
                    </Item>
                    <Item onClick={this.toggle} href="https://weprodigi.com/snapchat-marketing-services">
                      Snapchat Marketing
                    </Item>
                    {/*<Item href="https://weprodigi.com/paid-advertising-services">Paid Advertising Services</Item>*/}
                    {/*<Item href="https://weprodigi.com/main/messenger-chatbots-and-stickers">Messenger Chatbots &amp; Stickers</Item>*/}
                  </Dropdown>
                  <Dropdown
                    title="Digital Marketing"
                    image={'https://weprodigi.com/uploads/images/header/digital-marketing.svg'}
                    level={2}
                    onClick={this.closeAll}
                  >
                    {/*<Item onClick={this.toggle}  href="/social-media-marketing">Social Media Marketing</Item>*/}
                    <Item onClick={this.toggle} href="https://weprodigi.com/seo/professional-seo-services">
                      SEO Services
                    </Item>
                    <Item onClick={this.toggle} href="https://weprodigi.com/paid-advertising-services">
                      Paid Advertising Services
                    </Item>
                    <Item href="https://weprodigi.com/main/messenger-chatbots-and-stickers">Messenger Chatbots </Item>
                  </Dropdown>
                  <p className="text-purple text-18 font-medium uppercase my-5">About</p>
                  <Dropdown title="ProDigi">
                    <Item href="https://weprodigi.com/contact-us" onClick={this.toggle} area-label={'links'}>
                      Contact us
                    </Item>
                    <Item href="https://weprodigi.com/about-us" onClick={this.toggle} area-label={'links'}>
                      About Company
                    </Item>
                    <Item href="https://weprodigi.com/team" onClick={this.toggle}>
                      Team
                    </Item>
                  </Dropdown>
                  <Dropdown title="Clients">
                    <Item href="https://weprodigi.com/main/cases">Cases</Item>
                    <Item href="https://weprodigi.com/testimonals" onClick={this.toggle}>
                      Testimonials
                    </Item>
                    <Item onClick={this.toggle} href="/industries">
                      Industries
                    </Item>
                  </Dropdown>
                  <a rel="noreferrer" href="/blog" className={st['nav-item'] + ' text-purple text-18 font-medium uppercase my-5'} arealabel={'links'}>
                    Blog
                  </a>
                </div>
                <div className="mt-auto text-center">
                  <p className="text-center mb-5">We Value your time</p>
                  <div className={'flex justify-center'}>
                    <a
                      href="/video-production/get-pricing"
                      rel="noreferrer"
                      area-label={'links'}
                      target="_blank"
                      className="inline-block mr-5 text-15 text-purple text-center rounded-5 border border-purple px-10 py-8 leading-none"
                    >
                      Get Pricing
                    </a>
                    <a
                      href="https://calendly.com/info-weprodigi/call-with-account-team-digital-marketing"
                      rel="noreferrer"
                      area-label={'links'}
                      target="_blank"
                      className="inline-block ml-5 text-15 text-purple text-center rounded-5 border border-purple px-10 py-8 leading-none"
                    >
                      Schedule a call
                    </a>
                  </div>
                  <div className="mt-15">
                    <a
                      href="https://www.facebook.com/weprodigi/"
                      rel="noreferrer"
                      target="_blank"
                      className="inline-block mr-23"
                      area-label={'links'}
                    >
                      <span className="icon icon-fb w-12 h-24 inline-block" />
                      <span className={'hidden'}>Home</span>
                    </a>
                    <a
                      href="https://www.instagram.com/weprodigi_agency/"
                      rel="noreferrer"
                      target="_blank"
                      className="inline-block mr-23"
                      area-label={'links'}
                    >
                      <span className="icon icon-insta w-24 h-24 inline-block" />
                      <span className={'hidden'}>Instagram</span>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/pro.digi/"
                      rel="noreferrer"
                      target="_blank"
                      className="inline-block mr-23"
                      area-label={'links'}
                    >
                      <span className="icon icon-linkedin  w-24 h-24 inline-block" />
                      <span className={'hidden'}>linkedin</span>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UC6mkepDFKswxQWS76hgOMBg"
                      rel="noreferrer"
                      target="_blank"
                      className="inline-block mr-23"
                      area-label={'links'}
                    >
                      <span className="icon icon-youtube w-24 h-24 inline-block" />
                      <span className={'hidden'}>youtube</span>
                    </a>
                    <a href="https://twitter.com/WeProdigi" rel="noreferrer" target="_blank" className="inline-block mr-0" area-label={'links'}>
                      <span className="icon icon-twitter w-24 h-24 inline-block" />
                      <span className={'hidden'}>twitter</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <Offer
          country={this.props.country}
          show={this.state.showOfferModal}
          hide={this.hideOfferModal}
          title={this.state.offerModalTitle}
          offerPrice={this.state.offerPrice}
          offerDuration={this.state.offerDuration}
        />
      </div>
    );
  }
}
export default Header;
